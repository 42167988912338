<template>
  <div class="new-label">
    <div class="label">
      <div class="upto">
        {{ $t('newLabel.new') }}
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.new-label{
  @apply bg-red text-white text-lg rounded px-1.5 py-[3px] font-bold absolute left-[-5px] top-[14px];
}
</style>
